import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

const AvatarPic = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 100, height: 100, quality: 95) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
          }
        }
      }
    }
  `)

  const author = data.site.siteMetadata?.author
  const avatar = data?.avatar?.childImageSharp?.fixed

  return (
    <div 
      className="avatar-div"
      style={{
        paddingBottom: `15px`,
      }}
    >
      {avatar && (
        <Image
          fixed={avatar}
          alt={author?.name || ``}
          className="avatar-pic"
          imgStyle={{
            borderRadius: `50%`,
          }}
        />
      )}
    </div>
  )
}

export default AvatarPic
